<template>
  <!-- 会员推广 -->
  <div class="nav">
    <p class="color-white">你的推广链接</p>
    <div class="vipinp d-flex">
      <input type="text" placeholder="请输入推广链接" class="color-white bg-purple" />
      <button class="color-white">复制</button>
    </div>
    <!-- 推广信息 -->
    <div class="viptitle">
      <div class="color-green d-flex f-13">
        <div style="width: 15%">级别</div>
        <div style="width: 20%">被引荐者</div>
        <div style="width: 20%">邀请总人数</div>
        <div style="width: 25%">被邀用户充值</div>
        <div style="width: 20%">您的余额</div>
      </div>
      <div class="color-white d-flex f-13">
        <div style="width: 15%">1</div>
        <div style="width: 20%">暂无</div>
        <div style="width: 20%">0</div>
        <div style="width: 25%">0.00</div>
        <div style="width: 20%">0.00</div>
      </div>
    </div>
    <!-- 等级信息 -->
    <div>
      <div class="titlenav d-flex color-green bg-purple">
        <div>级别</div>
        <div>下级需要</div>
        <div>百分比</div>
        <div>收益</div>
      </div>
      <div class="titlenav d-flex bg-black color-white f-13">
        <div>LV1</div>
        <div>0.00</div>
        <div>1.00%</div>
        <div>1.00</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang='scss'>
.nav {
  height: 100vh;
  p {
    margin: 18px 15px;
  }
  .vipinp {
    justify-content: space-evenly;
    align-items: baseline;
    input {
      width: 240px;
      height: 55px;
      text-align: center;
      outline: none;
      border: none;
      border-radius: 15px;
      margin-right: 10px;
    }
    input::placeholder {
      color: white;
    }
  }
  button {
    width: 100px;
    height: 37px;
    border: 1px solid #70ec00;
    background-color: #12830d;
    box-shadow: 0 4px 0 2px #0fa051;
    font-weight: none;
    border-radius: 5px;
  }
  .viptitle {
    padding: 10px 0;
    width: 355px;
    height: 100px;
    margin: 18px auto;
    border: 3px solid #d4b9fc;
    background-color: #7b6fd1;
    border-radius: 15px;
    font-weight: none;
    box-sizing: border-box;
    div {
      div {
        margin-top: 15px;
        text-align: center;
      }
    }
  }
  .titlenav {
    height: 47px;
    align-items: center;
    justify-content: space-around;
    @media (min-width: 550px) {
      width: 90%;
      margin: auto;
    }
  }
}
</style>